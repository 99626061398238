import galaxy from "../images/GALAXY.png"
import NOVA from "../images/NOVA.png"
import supernova from "../images/SUPERNOVA.png"
import check from "../images/CHECK CARD.png"
import { useContext, useEffect, useRef, useState } from "react"
import React from "react"
import { GET } from "../https"
import { ScrollbarContext } from "../store"
import "react-simple-keyboard/build/css/index.css"
import ReactMarkdown from "react-markdown"
import Form from "./form"
import Left from "../svgs/Left.svg"
import Right from "../svgs/Right.svg"
import cross from "../svgs/error-cross-svgrepo-com.svg"
import { Link } from "react-router-dom"

const Packages = () => {
  const { language } = useContext(ScrollbarContext)
  const [selected, setSelected] = useState("")
  const [enable, setEnable] = useState(null)
  const [formData, setFormData] = useState({})
  const [packageData, setPackageData] = useState({})
  const [showAndroidKey, setShowAndroidKey] = useState(false)
  const [isChcked, setIsChecked] = useState(false)
  const [mobile, setMobile] = useState(false)

  const inputElement = useRef(null)
  const ref_nova = useRef(null)

  const { title_package, subtitle_package, subtitle_form } = formData

  useEffect(() => {
    if (navigator.userAgent.indexOf("Android") !== -1) {
      setShowAndroidKey(true)
      inputElement.current.focus()
    }
  }, [showAndroidKey])

  useEffect(() => {
    const nova = document.querySelector(".nova")
    const supernova = document.querySelector(".supernova")
    const galaxy = document.querySelector(".galaxy")

    const novaEvent = () => {
      setSelected("Nova")
      nova.classList.toggle("no")
      supernova.classList.remove("super")
      galaxy.classList.remove("gal")
      if (nova.classList.contains("no")) {
        setEnable(true)
      } else {
        setEnable(false)
      }
    }
    const supernovaEvent = () => {
      setSelected("Supernova")
      supernova.classList.toggle("super")
      nova.classList.remove("no")
      galaxy.classList.remove("gal")
      if (supernova.classList.contains("super")) {
        setEnable(true)
      } else {
        setEnable(false)
      }
    }

    const galaxyEvent = () => {
      setSelected("Galaxy")
      galaxy.classList.toggle("gal")
      supernova.classList.remove("super")
      nova.classList.remove("no")
      if (galaxy.classList.contains("gal")) {
        setEnable(true)
      } else {
        setEnable(false)
      }
    }

    nova.addEventListener("click", novaEvent)
    supernova.addEventListener("click", supernovaEvent)
    galaxy.addEventListener("click", galaxyEvent)

    return () => {
      nova.removeEventListener("click", novaEvent)
      supernova.removeEventListener("click", supernovaEvent)
      galaxy.removeEventListener("click", galaxyEvent)
    }
  }, [enable])
  const card = useRef(null)
  const slideWidth = 35

  useEffect(() => {
    const moveSlider = (slideIndex) => {
      const slidePosition = (slideIndex - 1) * -slideWidth
      card.current.style.transform = `translateX(${slidePosition}%)`
    }

    const moveToSlide = (slideIndex) => {
      const currentSlide = card.current.querySelector(".current-slide")
      const nextSlide = card.current.children[slideIndex]

      currentSlide.classList.remove("current-slide")
      nextSlide.classList.add("current-slide")
      moveSlider(slideIndex)
    }

    const btnLeft = document.querySelector(".pkg__left")
    const btnRight = document.querySelector(".pkg__right")

    let currentIndex = 1 // Start from the middle card

    moveSlider(currentIndex) // Initialize the slider position

    btnLeft?.addEventListener("click", () => {
      if (currentIndex > 0) {
        currentIndex--
        moveToSlide(currentIndex)
      }
    })

    btnRight?.addEventListener("click", () => {
      if (currentIndex < card.current.children.length - 1) {
        currentIndex++
        moveToSlide(currentIndex)
      }
    })

    return () => {
      btnLeft?.removeEventListener("click")
      btnRight?.removeEventListener("click")
    }
  }, [mobile])

  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/home-page?locale=${language}&populate=*`)
        setFormData(result?.data.attributes)
      } catch (err) {
        console.log("server failed to fetch", err)
      }
    }
    fetchHome()
  }, [language])

  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/packages?locale=${language}&populate=*`)
        setPackageData(result?.data)
      } catch (err) {
        console.log("server failed to fetch", err)
      }
    }
    fetchHome()
  }, [language])

  useEffect(() => {
    const portChange = () => {
      if (window.innerWidth < 800) {
        setMobile(true)
      } else {
        setMobile(false)
      }
    }
    window?.addEventListener("resize", portChange)
    portChange()

    return () => {
      window?.removeEventListener("resize", portChange)
    }
  }, [mobile])
  const select = language === "it" ? "SELEZIONA" : "SELECT"

  return (
    <section
      style={{ paddingBottom: showAndroidKey ? "30px" : "" }}
      className="pkg"
    >
      <div className="pkg__card-header">
        <p className="pkg__card-header-title">{title_package}</p>
        <p className="pkg__card-header-text">{subtitle_package}</p>
      </div>
      {mobile && <img className="pkg__left" src={Left} alt="Left arrow" />}
      <div ref={card} className="pkg__card-wrapper">
        <div ref={ref_nova} className="pkg__card-1 nova current-slide">
          <div className="pkg__card-1--avatar">
            <img src={NOVA} alt="nova" />
          </div>
          <h4 className="pkg__card-1--name">
            {packageData ? packageData[0]?.attributes?.package_name : ""}
          </h4>
          <p className="description">
            <ReactMarkdown>
              {packageData ? packageData[0]?.attributes?.description : ""}
            </ReactMarkdown>
          </p>
          <div className="pkg__card-1-offer-wrapper">
            {packageData[0]?.attributes.list_pack?.map((mark, key) => {
              return (
                <>
                  {mark.isTitle && (
                    <p
                      style={{
                        color: "white",
                        fontSize: "2rem",
                        alignSelf: "flex-start",
                        marginTop: 10,
                      }}
                    >
                      {mark.tick_mark}
                    </p>
                  )}
                  {!mark.isTitle && (
                    <figure
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      key={key}
                      className={
                        mark.available
                          ? "pkg__card-1--offer"
                          : !mark.available
                          ? "pkg__card-1--offerC"
                          : undefined
                      }
                    >
                      {mark.available ? (
                        <img key={key} src={check} alt="tick-mark" />
                      ) : !mark.isTitle ? (
                        <img key={key} src={cross} alt="tick-mark" />
                      ) : undefined}
                      <figcaption
                        style={{
                          display: "inline-block",
                        }}
                      >
                        {mark.tick_mark}
                      </figcaption>
                    </figure>
                  )}
                </>
              )
            })}
          </div>
          <div className="pkg__card-1--border"></div>
          <p className="pkg__card-1--price-tag">
            &euro;
            {packageData
              ? packageData[0]?.attributes.price.toLocaleString()
              : ""}{" "}
            <span>{language === "en" ? "" : ""}</span>
            {/*<span>
              {language === "en"
                ? "(before at least 6 months)"
                : "(per almeno 6 mesi)"}
            </span>*/}
          </p>
          <p className="pkg__card-1--discount">
            {language === "en"
              ? "Or save with 12 months at"
              : "Oppure risparmia con 12 mesi a "}
            <span className="pkg__card-1--discountC">
              &euro;
              {packageData
                ? packageData[0]?.attributes.current_price.toLocaleString()
                : ""}{" "}
            </span>
            <strong>
              &euro;
              {packageData
                ? packageData[0]?.attributes.discount_price.toLocaleString()
                : ""}
            </strong>
          </p>
          <button className="pkg__card-1--btn">{select}</button>
        </div>
        <div className="pkg__card-1 supernova">
          <div className="pkg__card-1--avatar">
            <img src={supernova} alt="supernova" />
          </div>
          <h4 className="pkg__card-1--name">
            {packageData ? packageData[1]?.attributes.package_name : ""}
          </h4>
          <p className="description">
            <ReactMarkdown>
              {packageData ? packageData[1]?.attributes?.description : ""}
            </ReactMarkdown>
          </p>
          <div className="pkg__card-1-offer-wrapper">
            {packageData[1]?.attributes.list_pack?.map((mark, key) => {
              return (
                <>
                  {mark.isTitle && (
                    <p
                      style={{
                        color: "white",
                        fontSize: "2rem",
                        alignSelf: "flex-start",
                        marginTop: 10,
                      }}
                    >
                      {mark.tick_mark}
                    </p>
                  )}
                  {!mark.isTitle && (
                    <figure
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      key={key}
                      className={
                        mark.available
                          ? "pkg__card-1--offer"
                          : !mark.available
                          ? "pkg__card-1--offerC"
                          : undefined
                      }
                    >
                      {mark.available ? (
                        <img key={key} src={check} alt="tick-mark" />
                      ) : !mark.isTitle ? (
                        <img key={key} src={cross} alt="tick-mark" />
                      ) : undefined}
                      <figcaption
                        style={{
                          display: "inline-block",
                        }}
                      >
                        {mark.tick_mark}
                      </figcaption>
                    </figure>
                  )}
                </>
              )
            })}
          </div>
          <div className="pkg__card-1--border"></div>
          <p className="pkg__card-1--price-tag">
            &euro;
            {packageData
              ? packageData[1]?.attributes?.price.toLocaleString()
              : ""}{" "}
            <span>{language === "en" ? "" : ""}</span>
            {/*<span>
              {language === "en"
                ? "(before at least 6 months)"
                : "(per almeno 6 mesi)"}
            </span>*/}
          </p>
          <p className="pkg__card-1--discount">
            {language === "en"
              ? "Or save with 12 months at"
              : "Oppure risparmia con 12 mesi a "}
            <span className="pkg__card-1--discountC">
              &euro;
              {packageData
                ? packageData[1]?.attributes.discount_price.toLocaleString()
                : ""}{" "}
            </span>
            <strong>
              &euro;
              {packageData
                ? packageData[1]?.attributes.current_price.toLocaleString()
                : ""}
            </strong>
          </p>
          <button className="pkg__card-1--btn">{select}</button>
        </div>
        <div className="pkg__card-1 galaxy">
          <div className="pkg__card-1--avatar">
            <img src={galaxy} alt="galaxy" />
          </div>
          <h4 className="pkg__card-1--name">
            {packageData ? packageData[2]?.attributes.package_name : ""}
          </h4>
          <p className="description">
            <ReactMarkdown>
              {packageData ? packageData[2]?.attributes?.description : ""}
            </ReactMarkdown>
          </p>
          <div className="pkg__card-1-offer-wrapper">
            {packageData[2]?.attributes.list_pack?.map((mark, key) => {
              return (
                <>
                  {mark.isTitle && (
                    <p
                      style={{
                        color: "white",
                        fontSize: "2rem",
                        alignSelf: "flex-start",
                        marginTop: 10,
                      }}
                    >
                      {mark.tick_mark}
                    </p>
                  )}
                  {!mark.isTitle && (
                    <figure
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      key={key}
                      className={
                        mark.available
                          ? "pkg__card-1--offer"
                          : !mark.available
                          ? "pkg__card-1--offerC"
                          : undefined
                      }
                    >
                      {mark.available ? (
                        <img key={key} src={check} alt="tick-mark" />
                      ) : !mark.isTitle ? (
                        <img key={key} src={cross} alt="tick-mark" />
                      ) : undefined}
                      <figcaption
                        style={{
                          display: "inline-block",
                        }}
                      >
                        {mark.tick_mark}
                      </figcaption>
                    </figure>
                  )}
                </>
              )
            })}
          </div>
          <div className="pkg__card-1--border"></div>
          <p className="pkg__card-1--price-tag">
            &euro;
            {packageData
              ? packageData[2]?.attributes?.price.toLocaleString()
              : ""}{" "}
            <span>{language === "en" ? "  " : ""}</span>
            {/*<span>
              {language === "en"
                ? "(before at least 6 months)"
                : "(per almeno 6 mesi)"}
            </span>*/}
          </p>
          <p className="pkg__card-1--discount">
            {language === "en"
              ? "Or save with 12 months at"
              : "Oppure risparmia con 12 mesi a "}
            <span className="pkg__card-1--discountC">
              &euro;
              {packageData
                ? packageData[2]?.attributes.current_price.toLocaleString()
                : ""}{" "}
            </span>
            <strong>
              &euro;
              {packageData
                ? packageData[2]?.attributes.discount_price.toLocaleString()
                : ""}
            </strong>
          </p>
          <button className="pkg__card-1--btn">{select}</button>
        </div>
      </div>
      {mobile && <img className="pkg__right" src={Right} alt="Right arrow" />}
      <p className="pkg__form-header">{subtitle_form}</p>
      <Form
        language={language}
        enable={enable}
        inputElement={inputElement}
        isChcked={isChcked}
        setIsChecked={setIsChecked}
        selected={selected}
        isCheckbox={true}
      />

      <div className="service-detail__brand_footer package">
        <div className="service-detail__brand_footer-together">
          <p className="service-detail__brand_footer-num">P.IVA 16413921004</p>
          <div className="service-detail__brand_footer-wrapper">
            <a
              href="https://www.iubenda.com/privacy-policy/53499605"
              target="_blank"
            >
              PRIVACY
            </a>
            <a
              href="https://www.iubenda.com/privacy-policy/53499605/cookie-policy"
              target="_blank"
            >
              COOKIES
            </a>
          </div>
        </div>
        <p className="service-detail__brand_footer-copyright">
          Copyright | Altair Agency 2023
        </p>
      </div>
    </section>
  )
}

export default Packages
