import AnimatedPage from "./page2"
import brand from "../svgs/Personal Brand & Brand Identity.svg"
import { gsap } from "gsap"
import { Fragment, useRef } from "react"
import arrow_left from "../images/arrow-left.svg"
import { useNavigate } from "react-router"
import { useContext, useEffect, useState } from "react"
import { ScrollbarContext } from "../store"
import {
  ScrollToContact,
  onReloadPage,
  pageHandler,
  restore,
  urlHander,
} from "../page_utile"
import { GET } from "../https"
import Meta from "../components/meta"
import ReactMarkdown from "react-markdown"
import { cachePos } from "../storage"
import { Link } from "react-router-dom"
import gas_blue from "../svgs/GRADIENT SFONDO 4.svg"
import galaxy from "../svgs/GALASSIA SFONDO.png"

const BrandPage = () => {
  const phone = window.matchMedia("(max-width:500px)")
  const [fetchData, setfetchData] = useState({})
  const { language } = useContext(ScrollbarContext)
  const [show, setShow] = useState(false)
  const nav = useNavigate()

  const refBrand = useRef(null)
  const { scroll, position, positionSetter } = useContext(ScrollbarContext)
  const [loader, setLoader] = useState(false)
  const { first_text, second_text, SEO, title } = fetchData

  useEffect(() => {
    const ScrollEvent = () => {
      if (
        window.location.href ===
        window.location.origin + urlHander(SEO?.seoTitle)
      ) {
        if (scroll.scrollTop < position) {
          scroll.scrollTo(0, position)
        }
        if (
          scroll.scrollTop >
          position + refBrand.current?.clientHeight * 0.5
        ) {
          scroll.scrollTo(0, position + refBrand.current?.clientHeight * 0.5)
        }
      }
    }
    scroll?.addListener(ScrollEvent)
    return () => {
      scroll?.removeListener(ScrollEvent)
    }
  })
  useEffect(() => {
    const setCache = async () => {
      if (position !== 0) {
        await cachePos.setItem("pos", position)
      }
    }
    setCache()
  }, [])
  useEffect(() => {
    cachePos.getItem("pos").then((value) => {
      onReloadPage(
        urlHander(SEO?.seoTitle),
        phone.matches ? 3507 : 3736,
        scroll,
        positionSetter
      )
    })
  })
  function ContactTranstion() {
    ScrollToContact(scroll, position, nav)
    restore()
  }
  useEffect(() => {
    setLoader(false)
    setShow(false)
    const fetchHome = async () => {
      try {
        const result = await GET(`/services?locale=${language}&populate=*`)
        setfetchData(result.data[0].attributes)
        setTimeout(() => {
          setShow(true)
          setLoader(true)
        }, 10)
      } catch (err) {
        console.log("server failed to fetch", err)
      }
    }
    fetchHome()
  }, [language])
  useEffect(() => {
    if (loader) {
      gsap.fromTo(
        refBrand.current,
        { y: 0 },
        {
          y: -refBrand.current?.clientHeight * 0.8,
          ease: "none",
          scrollTrigger: {
            target: "#smooth",
            start: position ? position : 3736,
            end: "+=800",
            scrub: true,
          },
        }
      )
    }
  })

  useEffect(() => {
    pageHandler(urlHander(SEO?.seoTitle), setShow)
    window.addEventListener("load", pageHandler)
    return () => {
      window.removeEventListener("load", pageHandler)
    }
  }, [])

  const pageTranstion = () => {
    gsap.to(".service-detail", { opacity: 0, duration: 2 })
    setTimeout(() => nav("/"), 2000)
    gsap.to(".servizi", { xPercent: 0, duration: 2 })
    gsap.to(".menu", { yPercent: 0, ease: "Expo.easeInOut" })
    scroll.scrollTo(0, position)
    restore()
  }

  const PreviewBrand = () => {
    return (
      <>
        {show && (
          <AnimatedPage>
            <div d ref={refBrand} className="service-detail__brand">
              <Meta
                title={SEO?.seoTitle}
                description={SEO?.seoDescription}
                keywords={SEO?.seoKeywords}
              />
              <h1 style={{ fontSize: "41px", color: "#fff" }}>{title}</h1>

              <ReactMarkdown>{first_text}</ReactMarkdown>

              <img src={brand} alt="social" />

              <ReactMarkdown>{second_text}</ReactMarkdown>

              <button
                className="service-detail__brand--btn"
                onClick={ContactTranstion}
              >
                {language === "en" ? "contacts" : "contatti"}
              </button>
              <div className="service-detail__brand_footer">
                <div className="service-detail__brand_footer-together">
                  <p className="service-detail__brand_footer-num">
                    P.IVA 16413921004
                  </p>
                  <div className="service-detail__brand_footer-wrapper">
                    <a
                      href="https://www.iubenda.com/privacy-policy/53499605"
                      target="_blank"
                    >
                      PRIVACY
                    </a>
                    <a
                      href="https://www.iubenda.com/privacy-policy/53499605/cookie-policy"
                      target="_blank"
                    >
                      COOKIES
                    </a>
                  </div>
                </div>
                <p className="service-detail__brand_footer-copyright">
                  Copyright | Altair Agency 2023
                </p>
              </div>
            </div>
            <img
              onClick={pageTranstion}
              src={arrow_left}
              alt="arrow-left"
              id="toggle"
              className="service-detail__left-arrow"
            />
          </AnimatedPage>
        )}
      </>
    )
  }
  return <Fragment>{show && <PreviewBrand />}</Fragment>
}

export default BrandPage
