import { useRef } from "react"
import { useNavigate } from "react-router"
import AnimatedPage from "./page2"
import { useEffect, useState } from "react"
import { useContext } from "react"
import { ScrollbarContext } from "../store"
import { gsap } from "gsap"
import arrow_left from "../images/arrow-left.svg"
import { GET } from "../https"
import {
  onReloadPage,
  pageHandler,
  ScrollToContact,
  urlHander,
} from "../page_utile"
import Meta from "../components/meta"
import ReactMarkdown from "react-markdown"
import { cachePos } from "../storage"
import { chiaBackward, toHome } from "../page_utile/pageTransition"
import { Link } from "react-router-dom"
import gas_blue from "../svgs/GRADIENT SFONDO 4.svg"
import galaxy from "../svgs/GALASSIA SFONDO.png"

const JoinUs = () => {
  const nav = useNavigate()
  const [fetchData, setfetchData] = useState({})
  const [mobile, setMobile] = useState(0)
  const [loader, setLoader] = useState(false)

  const { language } = useContext(ScrollbarContext)
  function restore() {
    gsap.to(".benefici", { display: "flex" })
    gsap.to(".rece", { display: "flex" })
    gsap.to(".contact", { display: "flex" })
  }
  const { TEXT, SEO, page } = fetchData
  const refBrand = useRef(null)
  const { scroll, position, positionSetter } = useContext(ScrollbarContext)
  const [show, setShow] = useState(false)
  useEffect(() => {
    positionSetter(0)
    const ScrollEvent = () => {
      if (
        window.location.href ===
        window.location.origin + urlHander(SEO?.seoTitle)
      ) {
        if (scroll.scrollTop < position) {
          scroll.scrollTo(0, position)
        }
        if (
          scroll.scrollTop >
          position + refBrand.current?.clientHeight * mobile
        ) {
          scroll.scrollTo(0, position + refBrand.current?.clientHeight * mobile)
        }
      }
    }
    scroll?.addListener(ScrollEvent)
    return () => {
      scroll?.removeListener(ScrollEvent)
    }
  })

  useEffect(() => {
    const portChange = () => {
      if (window.innerWidth < 800) {
        setMobile(0.55)
      } else {
        setMobile(0.5)
      }
    }
    window?.addEventListener("resize", portChange)
    portChange()

    return () => {
      window?.removeEventListener("resize", portChange)
    }
  }, [mobile])
  useEffect(() => {
    const setCache = async () => {
      if (position !== 0) {
        await cachePos.setItem("pos", position)
      }
    }
    setCache()
  }, [])

  useEffect(() => {
    cachePos.getItem("pos").then((value) => {
      onReloadPage(urlHander(SEO?.seoTitle), 0, scroll, positionSetter)
    })
  })

  function ContactTranstion() {
    chiaBackward()
    ScrollToContact(scroll, position, nav, positionSetter)
    restore()
  }
  useEffect(() => {
    onReloadPage(urlHander(SEO?.seoTitle), position, scroll, positionSetter)
  })
  useEffect(() => {
    setShow(false)
    setLoader(false)
    const fetchHome = async () => {
      try {
        const result = await GET(`/pages?locale=${language}&populate=*`)
        setTimeout(() => {
          setShow(true)
        }, 10)
        setTimeout(
          () => {
            setLoader(true)
          },
          mobile == 0.55 ? 4000 : 1000
        )

        setfetchData(result.data[3].attributes)
      } catch (err) {
        console.log("server failed to fetch", err)
      }
    }
    fetchHome()
  }, [language])

  useEffect(() => {
    if (loader) {
      gsap.fromTo(
        refBrand.current,
        { y: 0 },
        {
          y: -refBrand.current?.clientHeight * 0.8,

          scrollTrigger: {
            target: "#smooth",
            start: position ? position : 0,
            end: "+=800",
            scrub: true,
          },
        }
      )
    }
  })

  useEffect(() => {
    pageHandler(urlHander(SEO?.seoTitle), setShow)
    window.addEventListener("load", pageHandler)
    return () => {
      window.removeEventListener("load", pageHandler)
    }
  }, [])

  const pageTranstion = () => {
    if (loader) {
      nav("/")
      toHome()
      scroll.scrollTo(0, position)
      restore()
    } else {
      return
    }
  }
  return (
    <>
      {show && (
        <AnimatedPage>
          <div ref={refBrand} className="service-detail__brand joinUs">
            <Meta
              title={SEO?.seoTitle}
              description={SEO?.seoDescription}
              keywords={SEO?.seoKeywords}
            />
            <img className="g page" src={gas_blue} alt="gas-effect" />
            <img src={galaxy} alt="galaxy" className="x" />
            <h1 style={{ fontSize: "41px", color: "#fff" }}>{page}</h1>

            <ReactMarkdown>{TEXT}</ReactMarkdown>

            <div className="service-detail__brand_footer">
              <div className="service-detail__brand_footer-together">
                <p className="service-detail__brand_footer-num">
                  P.IVA 16413921004
                </p>
                <div className="service-detail__brand_footer-wrapper">
                  <a
                    href="https://www.iubenda.com/privacy-policy/53499605"
                    target="_blank"
                  >
                    PRIVACY
                  </a>
                  <a
                    href="https://www.iubenda.com/privacy-policy/53499605/cookie-policy"
                    target="_blank"
                  >
                    COOKIES
                  </a>
                </div>
              </div>
              <p className="service-detail__brand_footer-copyright">
                Copyright | Altair Agency 2023
              </p>
            </div>
          </div>
          <img
            onClick={pageTranstion}
            src={arrow_left}
            alt="arrow-left"
            id="toggle"
            className="service-detail__left-arrow"
          />
        </AnimatedPage>
      )}
    </>
  )
}

export default JoinUs
