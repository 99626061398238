import { useRef } from "react"
import { useNavigate } from "react-router"
import tele from "../svgs/Ricerche e Analisi.svg"
import AnimatedPage from "./page2"
import { useEffect, useState } from "react"
import { useContext } from "react"
import { ScrollbarContext } from "../store"
import { gsap } from "gsap"
import arrow_left from "../images/arrow-left.svg"
import { GET } from "../https"
import {
  onReloadPage,
  pageHandler,
  ScrollToContact,
  urlHander,
} from "../page_utile"
import Meta from "../components/meta"
import ReactMarkdown from "react-markdown"
import { cachePos } from "../storage"
import { Link } from "react-router-dom"

const TelescopePage = () => {
  const phone = window.matchMedia("(max-width:500px)")
  const [loader, setLoader] = useState(false)
  const [show, setShow] = useState(false)
  const [fetchData, setfetchData] = useState({})
  const nav = useNavigate()
  function restore() {
    gsap.to(".benefici", { display: "flex" })
    gsap.to(".rece", { display: "flex" })
    gsap.to(".contact", { display: "flex" })
  }
  const { first_text, img_brand, second_text, SEO, title } = fetchData
  const refBrand = useRef(null)
  const { scroll, position, language, positionSetter } =
    useContext(ScrollbarContext)

  useEffect(() => {
    const ScrollEvent = () => {
      if (
        window.location.href ===
        window.location.origin + urlHander(SEO?.seoTitle)
      ) {
        if (scroll.scrollTop < position) {
          scroll.scrollTo(0, position)
        }
        if (
          scroll.scrollTop >
          position + refBrand.current?.clientHeight * 0.4
        ) {
          scroll.scrollTo(0, position + refBrand.current?.clientHeight * 0.4)
        }
      }
    }
    scroll?.addListener(ScrollEvent)
    return () => {
      scroll?.removeListener(ScrollEvent)
    }
  })

  useEffect(() => {
    const setCache = async () => {
      if (position !== 0) {
        await cachePos.setItem("pos", position)
      }
    }
    setCache()
  }, [])

  useEffect(() => {
    cachePos.getItem("pos").then((value) => {
      onReloadPage(
        urlHander(SEO?.seoTitle),
        phone.matches ? 3567 : 3736,
        scroll,
        positionSetter
      )
    })
  })
  useEffect(() => {
    setLoader(false)
    setShow(false)
    const fetchHome = async () => {
      try {
        const result = await GET(`/services?locale=${language}&populate=*`)
        setfetchData(result.data[1].attributes)
        setTimeout(() => {
          setShow(true)
          setLoader(true)
        }, 10)
      } catch (err) {
        console.log("server failed to fetch", err)
      }
    }
    fetchHome()
  }, [language])

  useEffect(() => {
    pageHandler(urlHander(SEO?.seoTitle), setShow)
    window.addEventListener("load", pageHandler)
    return () => {
      window.removeEventListener("load", pageHandler)
    }
  }, [])
  useEffect(() => {
    if (loader) {
      gsap.fromTo(
        refBrand.current,
        { y: 0 },
        {
          y: -refBrand.current?.clientHeight * 0.8,

          ease: "none",
          scrollTrigger: {
            target: "#smooth",
            start: position ? position : phone.matches ? 3567 : 3749,
            end: "+=800",
            scrub: true,
          },
        }
      )
    }
  })

  const pageTranstion = () => {
    gsap.to(".service-detail", { opacity: 0, duration: 2 })
    setTimeout(() => nav("/"), 2000)
    setTimeout(() => gsap.to(".servizi", { xPercent: 0, duration: 2 }), 300)

    gsap.to(".menu", { yPercent: 0, ease: "Expo.easeInOut" })
    scroll.scrollTo(0, position)
    restore()
  }
  function ContactTranstion() {
    ScrollToContact(scroll, position, nav)
    restore()
  }
  return (
    <>
      {show && (
        <AnimatedPage>
          <div d ref={refBrand} className="service-detail__brand">
            <Meta
              title={SEO?.seoTitle}
              description={SEO?.seoDescription}
              keywords={SEO?.seoKeywords}
            />
            <h1 style={{ fontSize: "41px", color: "#fff" }}>{title}</h1>

            <ReactMarkdown>{first_text}</ReactMarkdown>

            <img src={tele} alt="social" />

            <ReactMarkdown>{second_text}</ReactMarkdown>

            <button
              className="service-detail__brand--btn"
              onClick={ContactTranstion}
            >
              {language === "en" ? "contacts" : "contatti"}
            </button>
            <div className="service-detail__brand_footer">
              <div className="service-detail__brand_footer-together">
                <p className="service-detail__brand_footer-num">
                  P.IVA 16413921004
                </p>
                <div className="service-detail__brand_footer-wrapper">
                  <a
                    href="https://www.iubenda.com/privacy-policy/53499605"
                    target="_blank"
                  >
                    PRIVACY
                  </a>
                  <a
                    href="https://www.iubenda.com/privacy-policy/53499605/cookie-policy"
                    target="_blank"
                  >
                    COOKIES
                  </a>
                </div>
              </div>
              <p className="service-detail__brand_footer-copyright">
                Copyright | Altair Agency 2023
              </p>
            </div>
          </div>
          <img
            onClick={pageTranstion}
            src={arrow_left}
            alt="arrow-left"
            id="toggle"
            className="service-detail__left-arrow"
          />
        </AnimatedPage>
      )}
    </>
  )
}

export default TelescopePage
